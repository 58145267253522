import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {

  return (
    <Layout>
      <SEO title="What is typewithme?" />
      <h1>About</h1>
      <p>
        With Type With Me, you will learn how to use tools, learn how to program and rule the world. Upgrade your skills by watching these tips and tricks on this channel and website!
        It is always nice to get you learn something new about <strong>typescript</strong>, <strong>node.js</strong>, <strong>rxjs</strong>, <strong>jest</strong> or ...
        Over time this website will grow in terms of technologies and content.
      </p>
      <p>Consider this website as a platform of the latest news around web technologies. If you have any comments feel free to reach out to us.</p>
      <p>
          This website is an initiative of programmer <a href="https://www.linkedin.com/in/jhermans85/">Joris Hermans</a>.
      </p>
    </Layout>
  )
} 
